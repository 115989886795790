<button id="span" class="btn-mobile" (click)="openNav()">&#9776; <h6>Dati Utente</h6> </button>
<div id="sidebar-wrapper" class="left-sidebar">
    <h5 style="padding: 5px 0 5px 0;">Dati Utente</h5>
    <button id="span" href="javascript:void(0)" class="closebtn" (click)="closeNav()">&times;</button>

    <div id="user">
        <h6>{{user?.nome}} {{user?.cognome}}</h6>
    </div>
    <div class="mb-3 animation">
        <label>Email</label>
        <span class="white">{{user?.email}}</span>
    </div>
    <div class="mb-3 animation">
        <label>Nome</label>
        <span class="white">{{user?.nome}}</span>
    </div>
    <div class="mb-3 animation">
        <label>Cognome</label>
        <span class="white">{{user?.cognome}}</span>
    </div>
    <div class="mb-3 animation" *ngIf="user?.codiceFiscale">
        <label>Codice fiscale</label>
        <span class="white">{{user?.codiceFiscale}}</span>
    </div>
    <div class="mb-3 animation" *ngIf="user?.partitaIVA">
        <label>P.IVA</label>
        <span class="white">{{user?.partitaIVA}}</span>
    </div>
</div>