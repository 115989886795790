<div *ngIf="loader">
  <app-spinner></app-spinner>
</div>
<div id="top-button">
  <div class="row " id="navbarDettaglio">
    <div class="col-1 align-self-center indietro">
      <a (click)="goBack()"><img src="../../../assets/img/arrow/gray_left.png" class="arrow"> <span class="gray"
          style="vertical-align: middle;padding-left: 5px;">Indietro</span></a>
    </div>
    <div class="col-9 align-self-center uppercase">
      <span class="gray" style="vertical-align: middle;padding-left: 5px;">Dettaglio &nbsp;&nbsp;/&nbsp;&nbsp;
        {{typeLista}} &nbsp;&nbsp;/&nbsp;&nbsp; {{itemUser?.nome}} {{itemUser?.cognome}}</span>
    </div>
  </div>
</div>
<div id="subheader" *ngIf="!fatturaPagata && statoEstrattoConto != 'Confermato'">
  <button class="btn btn-primary" (click)="confermaPagamento()">Conferma Pagamento</button>
</div>
<div class="row justify-content-center" id="main-content">
  <div class="col-lg-2" style="padding: 0;">
    <app-sidebar-dettaglio [user]="itemUser"></app-sidebar-dettaglio>
  </div>
  <div class="col-lg-10" style="padding: 90px 0 0 0;">
    <div class="d-flex">
      <div class="container-detail">
        <div id="Riepilogo" class="row data-container" style="padding: 20px 0 0 20px">
          <div class="col-12 p-0" style="margin-bottom: 20px">
            <h5 class="gray mb-0">Riepilogo {{mese}}</h5>
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col-4 pl-0 divider-riepilogo">
                <div>
                  <h5 class="gray">Info</h5>
                </div>
                <span class="label" style="margin-bottom: 3px; display: block;">Confermato il<br></span>
                <span class="value"><input class="form-control col-6" type="text" disabled
                    value="{{dataConferma}}"></span>
                <span
                  style="font-size: 13px;    color: #7c899a;    clear: both;display: block;    margin-top: 5px;">{{statoEstrattoConto}}</span>
                <div class="row justify-content-between align-items-center" style="margin-top: 20px">
                  <div class="col-6">
                    <button class="btn btn-outline-secondary btn-riepilogo " (click)="download()"
                      [hidden]="this.typeLista == 'confermati' ">Download {{tipoDownload}}</button>
                  </div>
                  <div class="col-6 text-right">
                    <img width="30px" height="20px" style="align-self: center; cursor: pointer;"
                      src="../../../assets/img/view@2x.png" alt="view" (click)="openModale('view')"
                      [hidden]="this.typeLista == 'confermati' ">
                    <img width="18px" height="20px" style="align-self: center; cursor: pointer; margin-left: 15px;"
                      src="../../../assets/img/delete@2x.png" alt="delete" (click)="openModale('note')"
                      [hidden]="this.typeLista == 'confermati' || this.typeLista == 'pagati'">
                  </div>
                </div>
              </div>
              <div class="col-4 pr-0 divider-riepilogo">
                <h5 class="gray">Estratti conto scaduti</h5>
                <h5 class="dark mb-0">Totale pregresso</h5>
                <h3 class="normal dark">{{fattura?.importoPregresso}}€</h3>


                <div class="d-flex" style="margin-top: 30px;">
                  <button class="btn btn-outline-secondary btn-riepilogo" (click)="openExpiredDetail()"
                    [hidden]="this.typeLista == 'confermati' ">Visualizza dettaglio</button>
                </div>

              </div>
              <div class="col-4 pl-0 pr-0">
                <h5 class="gray" style="margin-left: 15px;">Totale da corrispondere</h5>
                <h5 class="dark mb-0" style="margin-left: 15px;">Riepilogo del mese</h5>
                <h3 class="normal dark" style="margin-left: 15px;">{{fattura?.importoMese}}€</h3>
                <div class="d-flex tot-fattura">
                  <div class="col-auto pl-0" [ngClass]="{'order-2': isPagamentoFattura}">
                    <h5 class="bold mb-0 dark normal">{{isPagamentoRicevuta ? 'Totale Lordo': 'Totale Fattura'}}</h5>
                    <h3 class="normal">{{fattura?.importoFattura}}€</h3>
                  </div>
                  <div class="col-auto pl-0">
                    <h5 class="bold mb-0 dark normal">{{isPagamentoRicevuta ? 'Netto': 'Imponibile'}}</h5>
                    <h3 class="normal">{{fattura?.importoImponibile}}€</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</div>



<div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true"
  [ngStyle]="{'display': modalType ? 'block' : 'none', 'opacity':1}">


  <div class="modal-dialog" *ngIf="modalNote" style="text-align:left; width: fit-content;">
    <div class="modal-content" style="margin-top:170px;">
      <div class="modal-header">
        <h4 class="modal-title text-left black">Rifiuta Allegato</h4>
      </div>
      <div class="modal-body">
        <label for="">Note:</label>
        <textarea cols="30" rows="10" maxlength="300"
          style="height: 100px; max-height: 200px; min-height: 60px; width: -webkit-fill-available;"
          [(ngModel)]="note"></textarea>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" style="margin: 0 auto; width: 164px !important;"
          (click)="openModale('chiudi')">Annulla</button>
        <button type="button" class="btn btn-primary" style="margin: 0 auto;" (click)="rifiutAllegati()"
          [disabled]="this.note == '' ">Conferma</button>
      </div>
    </div>
  </div>

  <div class="modal-dialog" *ngIf="showDoc">
    <div class="modal-content mt-5" style="width: 100%; height: 80vh;">
      <div *ngIf="Myloader" class="text-center mt-5 pt-5">
        <div class="spinner-border text-warning" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <ngx-doc-viewer *ngIf="!Myloader" [url]="this.url" viewer="url" style="width:100%;height:70vh;"></ngx-doc-viewer>
      <div class="modal-footer" *ngIf="!Myloader">
        <button type="button" class="btn btn-outline-secondary" style="margin: 0 auto; width: 164px !important;"
          (click)="openModale('chiudi')">Chiudi</button>
      </div>
    </div>
  </div>

</div>



<div class="modal-backdrop fade show" [ngStyle]="{'display': modalType ? 'block' : 'none', 'opacity': 0.5}"></div>