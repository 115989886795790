import { Component, OnInit } from '@angular/core';
import { OperatorsService } from '../../Services/Operatori/operator.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
 

  constructor() { }

  ngOnInit() {
   
  }

  

}