import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';

import { ModalService } from './Helpers/modal.service';
import { AuthService } from './Services/Authentication/auth.service';
import { NetworkingService } from './Services/Networking/networking.service';
import { OperatorsService } from './Services/Operatori/operator.service';
import { UtentiService } from './Services/Utenti/utenti.service';
import { PreviousRouteService } from './Services/previous-route.service';
import { ServiceSearchService } from './Services/service-search.service';
import { StoreItemService } from './Services/store-item.service';

import { CommonModule } from '@angular/common';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { HomeComponent } from '../app/Components/home/home.component';
import { ListaComponent } from '../app/Components/lista/lista.component';
import { DettaglioComponent } from './Components/dettaglio/dettaglio.component';
import { ExpiredAccountStatementsModalComponent } from './Components/expired-account-statements-modal/expired-account-statements-modal.component';
import { FilterComponent } from './Components/filter/filter.component';
import { HeaderComponent } from './Components/header/header.component';
import { ModalConfirmComponent } from './Components/modal-confirm/modal-confirm.component';
import { SidebarDettaglioComponent } from './Components/sidebar-dettaglio/sidebar-dettaglio.component';
import { TableComponent } from './Components/table/table.component';
import { NewLoaderComponent } from './Ui/new-loader/new-loader.component';
import { SpinnerComponent } from './Ui/spinner/spinner.component';
import { AppComponent } from './app.component';

@NgModule({
   declarations: [
      AppComponent,
      NewLoaderComponent,
      SpinnerComponent,
      HomeComponent,
      ListaComponent,
      HeaderComponent,
      FilterComponent,
      DettaglioComponent,
      SidebarDettaglioComponent,
      ModalConfirmComponent,
      TableComponent,
      ExpiredAccountStatementsModalComponent,
   ],
   imports: [
      BrowserModule,
      AppRoutingModule,
      HttpClientModule,
      FormsModule,
      ReactiveFormsModule,
      NgbModule,
      NgbModalModule,
      NgxDocViewerModule,
      CommonModule
   ],
   providers: [
      AuthService,
      NetworkingService,
      UtentiService,
      PreviousRouteService,
      ServiceSearchService,
      ModalService,
      StoreItemService,
      OperatorsService,
   ],
   entryComponents: [
      ExpiredAccountStatementsModalComponent,
   ],
   bootstrap: [
      AppComponent
   ]
})
export class AppModule { }
