import { Injectable } from '@angular/core';
import { NetworkingService } from '../Networking/networking.service';
import {EstrattoConto} from '../../Models/EstrattoConto'
import { DettaglioEstrattoConto } from 'src/app/Models/DettaglioEstrattoConto';
import { ApiVersion } from 'src/app/Enums/ApiVersion';

@Injectable({
    providedIn: 'root'
  })
export class EstrattiContoService {
    
    constructor(private network:NetworkingService){}
    
    
    getListaEstrattoConto(pageIndex:number = 0,pageSize:number = 10,insoorerId:string="", from?:number , to?:number){

        let queryString =  "?&pageIndex="+pageIndex+"&pageSize="+pageSize+"&insoorerId="+insoorerId+"&api-version=v1";

        if(from) queryString += "&from="+from;
        if(to)   queryString += "&to="+to;         

        return this.network.get(ApiVersion.v2 + "/EstrattiConto/Mensile/search" + queryString,this.network.getAuthenticatedHeader(),'application/json')
        .map(data=>{
            let elenco = EstrattoConto.fromJsonArray(data['data'])
            let meta = EstrattoConto.getListMeta(data['meta'])
            return {
                "elenco":elenco,
                "meta":meta
            }
        })

    }

    getDettaglioMeseEstrattoConto(id:number){
        return this.network.get(ApiVersion.v2 + "/EstrattiConto/Mensile/"+id,this.network.getAuthenticatedHeader(),'application/json')
        .map(data=>{
            let elenco = DettaglioEstrattoConto.fromJson(data)
            return elenco
        })
    }

    postRettifica(body,id){
        return this.network.post(ApiVersion.v2 + "/EstrattiConto/mensile/"+id+"/rettifica",body,this.network.getAuthenticatedHeader(),'application/json')
        .map(data=>{
            return data;
        })
    }

    deleteRettifica(id,idRettifica){
        return this.network.delete(ApiVersion.v2 + "/EstrattoConto/mensile/"+id+"/rettifica/"+idRettifica,this.network.getAuthenticatedHeader(),'application/json')
        .map(data=>{
            return data;
        })
    }


    getListaTipologiaRettifiche(){
        return this.network.get(ApiVersion.v2 + "/tipologiarettifica",this.network.getAuthenticatedHeader(),'application/json')
        .map(data=>{
            return data;
        })
    }
    
}