import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http";
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../../environments/environment';
import 'rxjs/Rx';



@Injectable()
export class NetworkingService {
  private endpoints = [environment.api_default_url, environment.api_auth_url, environment.api_authapi_url, ""];

  public Url;

  constructor(private http: HttpClient, private router: Router) { }
  post(path: string, data: any = {}, headers: any = {}, contentType: string = 'application/json', endpoint = Endpoints.default, respContType: string = 'application/json') {
    return this.http.post(
      this.buildUrl(endpoint, path), data, { headers: this.buildOptions(headers, contentType, respContType), observe: 'response'}
    ).map((dataResponse: HttpResponse<Object>) => {
      var contentType = dataResponse.headers.get("content-type");
      if (contentType != undefined && respContType == 'blob') {
        return dataResponse
      } else {
        let json = dataResponse.body;
        return json;
      }
    }).catch(
      error => {
        this.checkStatus(error);
        return Observable.throw(error)
      }
    )
  }

  get(path: string, headers: any = {}, contentType: string = 'application/json', data: any = {}, endpoint = Endpoints.default) {
    this.Url = this.buildUrl(endpoint, path, data);
    return this.http.get(
      this.buildUrl(endpoint, path, data), { headers: this.buildOptions(headers, contentType) }
    ).map((data:HttpResponse<Object>) => {
      let json = data;

      return json;
    }).catch(
      error => {
        this.checkStatus(error);

        return Observable.throw(error)
      }
    )
  }

  put(path: string, data: any = {}, headers: any = {}, contentType: string = 'application/json', endpoint = Endpoints.default) {
    return this.http.put(
      this.buildUrl(endpoint, path), data, { headers: this.buildOptions(headers, contentType) }
    ).map((data: HttpResponse<Object>) => {
      let json = data;
      return json;
    }).catch(
      error => {
        this.checkStatus(error);
        return Observable.throw(error)
      }
    )
  }

  delete(path: string, headers: any = {}, contentType: string = 'application/json', endpoint = Endpoints.default) {
    return this.http.delete(
      this.buildUrl(endpoint, path), { headers: this.buildOptions(headers, contentType) }
    ).map((data: HttpResponse<Object>) => {
      let json = data;
      return json;
    }).catch(
      error => {
        this.checkStatus(error);
        return Observable.throw(error)
      }
    )
  }


  public getAuthenticatedHeader() {
    return [{ "key": "Authorization", "value": this.buildBearerToken() }]
  }

  buildBearerToken() {
    return "Bearer " + this.getAuthenticationTokenFromLocalStorage();
  }

  getAuthenticationTokenFromLocalStorage() {
    return localStorage.getItem('access_token');
  }
  buildUrl(endpoint: Endpoints, path: string, data: any = {}) {
    let qm = "?"
    /* if (path.indexOf(qm) > -1) {
       qm = "&"
     }*/

    let useFakes = ""
    if (environment.UseFakes) {
      useFakes = "&UseFakes=1"
    } else {
      useFakes = ""
    }
    //console.log(this.endpoints[endpoint] + [path, this.serialize(data)].join(""))
    return this.endpoints[endpoint] + [path, this.serialize(data)].join("")
  }

  //Questa funzione è necessaria per verificare che la chiamata sia stata autorizzata, in caso contrario chiamerà la funzione unauthorized()
  checkStatus(error) {
    if (error.status === 401) {
      this.unauthorized();
    }
  }

  //Questa funzione effettuerà un redirect sulla pagina di login
  unauthorized() {
    console.warn("non autorizzato");
    localStorage.clear();
    this.router.navigateByUrl("/");
  }

  //Questa funzione ha lo scopo di andare a costruire gli headers di ogni chiamata
  buildOptions(headers: any[] = [], contentType: string = 'application/json', responseType: string = 'application/json') {
    //let h = new HttpHeaders().set('Content-Type', contentType);
    let h = new HttpHeaders({ 'Content-Type': contentType });
    if (contentType == "multipart/form-data") {
      h.delete("Content-Type")
    }

    headers.forEach(item => {
      h = h.append(item['key'], item['value'])
    });
     h = h.append("Accept-Language", "IT-IT")
    return h;
  }

  serialize(obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }


}


export enum Endpoints {
  default = 0,
  auth = 1,
  authapi = 2,
  none = 3
}
