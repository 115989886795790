import { Injectable } from '@angular/core';
import { GestioneUtente } from 'src/app/Models/GestioneUtente';
import { NetworkingService } from '../Networking/networking.service';

@Injectable({
  providedIn: 'root'
})
export class UtentiService {

  constructor(private networking: NetworkingService) { }

  getUtenti(name: string = "", email: string = "", state: number = 0, sort: number = 0, isSorted: boolean = false, hideDisabled: boolean = true, pageNumber: number = 0) {

    let queryString = "";
    queryString += "?&numberOfElementsPerPage=10";
    queryString += "&inspectorSortingField=" + sort;
    queryString += "&isSortedDescending=" + isSorted;
    queryString += "&hideDisabled=" + hideDisabled;

    if (name != "") queryString += "&name=" + name;
    if (email != "") queryString += "&email=" + email;
    if (state != 0) queryString += "&state=" + state;
    if (pageNumber > 0) queryString += "&pageNumber=" + (pageNumber - 1);

    return this.networking.get("AnagraficaInsoorer/" + queryString, this.networking.getAuthenticatedHeader(), 'application/json')
      .map(data => {
        console.log(data)
        /* let users = ListaUtentiCommunity.fromJsonArray(data.data) */
        return {
          /*  "users": users,
           "meta": ListaUtentiCommunity.getListMeta(data.meta) */
        }
      })
  }

  getInspectorById(id: string) {
    return this.networking.get("v1/Administration/gestioneUtenti/" + id, this.networking.getAuthenticatedHeader(), 'application/json')
      .map(
        data => {
          return new GestioneUtente(data)
        });
  }
}