import { Component, OnInit } from '@angular/core';
import { TableStructure } from 'src/app/Helpers/TableStructure';
import { FattureService } from '../../Services/Fatture/fatture.service';
import { StoreItemService } from 'src/app/Services/store-item.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-lista',
  templateUrl: './lista.component.html',
  styleUrls: ['./lista.component.scss']
})
export class ListaComponent implements OnInit {

  public goToPageNumber: number = 1;
  public pagate: boolean = false;
  public loader: boolean = false;
  public type:string = "";
  public sort: number = 1;
  public sortDirection: number;
  public tipologiaPagamento:number;
  public anno: number;
  public mese: string;
  public sortDescending: boolean;
  public tableStructure: any[] = TableStructure.Lista;
  public items: any[] = [];
  public meta: any = {};
  public calendarAvailable: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private fattureService: FattureService,private storeItemService:StoreItemService) { }

  ngOnInit() {
    this.anno = (new Date()).getFullYear()
    let filter = this.storeItemService.read('savedFilter')
    this.setFilter(filter, false);
  }

  getFatture(type:string, fromFilter = false) {
    this.tableStructure = type == 'scadute' ? TableStructure.Scadute : TableStructure.Lista;
    this.items = [];
    this.type = type;
    this.loader = true;
    
    let filter = {
      anno: this.anno,
      mese: this.mese,
      sortField: this.sort,
      sortDirection: this.sortDirection,
      tipoPagamento: this.tipologiaPagamento,
    };
    
    this.storeItemService.write("type",this.type);

    !fromFilter && type == 'scadute' && this.fattureService.getScaduteDates()
    .subscribe(dates => {
      this.loader = false;
      let firstYear = '';
      let firstMonth = 1;
      let calendar = this.calendarAvailable.getValue() || {};
      dates.forEach((date, idx) => {
        const year = date.toString().substring(0, 4);
        const month = +date.toString().substring(5, 7);
        if (idx == 0) {
          firstYear = year;
          firstMonth = month;
        }
        if (calendar[year] && calendar[year].length) {
          calendar[year].push(month);
        } else {
          calendar[year] = [month];
        }
      });
      this.calendarAvailable.next(calendar);

      filter.anno = +firstYear;
      filter.mese = firstMonth+'';
      this.fattureService.getFatture(filter,this.type)
      .subscribe(data => {
        this.items = data;
        this.loader = false;
      });
      // console.log(this.calendarAvailable);
    });

    !!fromFilter && type == 'scadute' && this.fattureService.getFatture(filter,this.type)
      .subscribe(data => {
        this.items = data;
        this.loader = false;
      });

    type != 'scadute' && this.fattureService.getFatture(filter,this.type)
      .subscribe(data => {
        this.items = data;
        this.loader = false;
      });
  }

  setFilter(event?, fromFilter = true) {
    console.log(event)
    if(event) this.anno = event.anno;
    if(event) this.mese = event.mese;
    if(event && event.tipologiaPagamento) this.tipologiaPagamento = event.tipologiaPagamento;

    if(this.storeItemService.read("type")) this.type = this.storeItemService.read("type");
    else if(this.type == "") this.type = "confermati";

    this.getFatture(this.type, fromFilter)
  }

  getTotalAmount(items) {
    return items.reduce((a, b) => a + b.amount, 0);
  }

  sortBy(event) {
  
    if(this.sortDirection == 1) this.sortDirection = 0;
    else this.sortDirection = 1;

    this.sort = event;
    this.getFatture(this.type)
  }

}
