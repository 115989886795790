
import { Convert } from 'src/app/Helpers/Utils';

export class Rettifica {
    constructor(
          public dataCreazione: string = "...",
          public id: string = "...",
          public importo: string = "...",
          public inseritaDa: string = "...",
          public note: string = "...",
          public tipologia: any [] = [],
    ){}


    public UnixTimeStamp = Convert.unixTimestampToLocalDateString;

    assignToObject(json:any){
        this.id = json.id;
        this.importo = json.importo+"€";
        this.note = json.note;
        this.dataCreazione = this.UnixTimeStamp(json.dataCreazione)
        this.tipologia = json.tipologia ? json.tipologia['value'] : [];
        this.inseritaDa = json.inseritaDa;
    }


      static fromJsonArray(json: any): Rettifica[] {
        //return json;
        return json.map(j => {
          return this.fromJson(j)
        })
      }
    
      static fromJson(json: any): Rettifica {
        let assignment: Rettifica;
        assignment = new Rettifica();
        assignment.assignToObject(json);
        return assignment;
      }
}