import { Convert } from 'src/app/Helpers/Utils';

export class Rimborsi {
    constructor(
        public id: string = "",
        public inspectorId: string = "",
        public tipologia: any[] = [],
        public tipologiaId: string = "",
        public tipologiaValue: string = "",
        public stato: any[] = [],
        public statoId: string = "",
        public statoValue: string = "",
        public importo: string = "",
        public note: string = "",
        public requestId: string = "",
        public shiftId: string = "",
        public data: string = "",
        public dataRichiestaRimborso: string = "",
        public allegati: any[] = [],
        public nome:string = "",
        public noteOperatore:string = "",

    ) { }

    public UnixTimeStamp = Convert.unixTimestampToLocalDateString;

    assignToObject(json: any) {
        this.id = json.id;
        this.inspectorId = json.inspectorId;
        this.tipologia = json.tipologia ? json.tipologia : [];
        this.tipologiaId = json.tipologia ? json.tipologia.id : [];
        this.statoId = json.stato ? json.stato.id : [];
        this.tipologiaValue = json.tipologia ? json.tipologia.value : [];
        this.statoValue = json.stato ? json.stato.value : [];
        this.importo = json.importo+"€";
        this.note = json.note;
        this.requestId = json.requestId;
        this.shiftId = json.shiftId;
        this.data = this.convertData(json.data);
        this.dataRichiestaRimborso = this.convertData(json.dataRichiestaRimborso);
        this.allegati = json.allegati;
        this.nome = json.inspectorFullName;
        this.noteOperatore = json.noteOperatore;
    }
    
    static fromJsonArray(json: any): Rimborsi[] {
        //return json;
        return json.map(j => {
            return this.fromJson(j)
        })
    }

    static fromJson(json: any): Rimborsi {
        let grant: Rimborsi;
        grant = new Rimborsi();
        grant.assignToObject(json);
        return grant;
    }

    static getListMeta(json: any) {
      let meta = {
        "zeroBasedPageIndex": json.zeroBasedPageIndex,
        "hasNextPage": json.hasNext,
        "hasPreviousPage": json.hasPrevious,
        "totalPages": json.totalPages,
        "count":json.count,
      }
      return meta
    }

    convertData(data:number){
        let dataConverted = this.UnixTimeStamp(data)
        return dataConverted
    }
}