import { CompensiRilevazioni } from './CompensiRilevazioni';
import { CompensiTurni } from './CompensiTurni';
import { Rettifica } from './Rettifica';
import { Rimborsi } from "./Rimborsi";
import { RimborsiKm } from './RimborsiKm';

import { Convert } from 'src/app/Helpers/Utils';
import { EstrattoContoNonRiscosso } from "./EstrattoContoNonRiscosso";
import { IdValue } from './IdValue';

export class DettaglioEstrattoConto {
  constructor(
    public idEstrattoConto: number = 0,
    public insoorerId: string = "...",
    public data: string = "...",
    public dataConferma: string = "...",
    public importo: string = "...",
    public numeroRimborsi: number = 0,
    public numeroRimborsiKm: number = 0,
    public numeroIndennitaTurni: number = 0,
    public numeroCompensiRilevazioni: number = 0,
    public numeroTurniEffettuati: number = 0,
    public numeroRilevazioniSvolte: number = 0,
    public numeroRilevazioniSvolteFuoriTurno: number = 0,
    public importoRilevazioni: string = "...",
    public importoTurni: string = "...",
    public totaleKmPercorsi: number = 0,
    public totaleKmRimborsati: number = 0,
    public importoRimborsoKm: string = "...",
    public numeroAltriRimborsi: number = 0,
    public importoAltriRimborsi: number = 0,
    public statoValue: string = "...",
    public statoId: string = "...",
    public rettifiche: Rettifica[] = [],
    public rimborsi: Rimborsi[] = [],
    public rimborsiKm: RimborsiKm[] = [],
    public compensiTurni: CompensiTurni[] = [],
    public compensiRilevazioni: CompensiRilevazioni[] = [],
    public estrattiContoNonRiscossi: EstrattoContoNonRiscosso[] = [],
    public inquadramentoRapporto: IdValue = new IdValue({})

  ) { }
  public UnixTimeStamp = Convert.unixTimestampToLocalDateString;

  assignToObject(json: any) {
    if (!json) return;
    this.idEstrattoConto = json.id;
    this.insoorerId = json.insoorerId;
    this.data = this.UnixTimeStamp(json.data);
    this.dataConferma = this.UnixTimeStamp(json.dataConferma);
    this.importo = json.importo;
    this.rimborsiKm = json.rimborsiKm;
    this.numeroRimborsi = json.numeroRimborsi;
    this.numeroRimborsiKm = json.numeroRimborsiKm;
    this.numeroIndennitaTurni = json.numeroIndennitaTurni;
    this.numeroCompensiRilevazioni = json.numeroCompensiRilevazioni;
    this.numeroTurniEffettuati = json.numeroTurniEffettuati;
    this.numeroRilevazioniSvolte = json.numeroRilevazioniSvolte;
    this.numeroRilevazioniSvolteFuoriTurno = json.numeroRilevazioniSvolteFuoriTurno;
    this.importoRilevazioni = json.importoRilevazioni;
    this.importoTurni = json.importoTurni;
    this.totaleKmPercorsi = json.totaleKmPercorsi;
    this.totaleKmRimborsati = json.totaleKmRimborsati;
    this.importoRimborsoKm = json.importoRimborsoKm;
    this.numeroAltriRimborsi = json.numeroAltriRimborsi;
    this.importoAltriRimborsi = json.importoAltriRimborsi;
    this.statoValue = json.stato ? json.stato['value'] : "";
    this.statoId = json.stato ? json.stato['id'] : "";
    this.rimborsi = json.rimborsi ? Rimborsi.fromJsonArray(json.rimborsi) : [];
    this.rettifiche = json.rettifiche ? Rettifica.fromJsonArray(json.rettifiche) : [];
    this.compensiRilevazioni = json.compensiRilevazioni ? CompensiRilevazioni.fromJsonArray(json.compensiRilevazioni) : [];
    this.rimborsiKm = json.rimborsiKm ? RimborsiKm.fromJsonArray(json.rimborsiKm) : [];
    this.compensiTurni = json.compensiTurni ? CompensiTurni.fromJsonArray(json.compensiTurni) : [];
    this.estrattiContoNonRiscossi = json.estrattiContoNonRiscossi ? EstrattoContoNonRiscosso.fromJsonArray(json.estrattiContoNonRiscossi) : [];
    this.inquadramentoRapporto = json.inquadramentoRapporto ? new IdValue(json.inquadramentoRapporto) : null
  }

  static fromJsonArray(json: any): DettaglioEstrattoConto[] {
    //return json;
    return json.map(j => {
      return this.fromJson(j)
    })
  }

  static fromJson(json: any): DettaglioEstrattoConto {
    let assignment: DettaglioEstrattoConto;
    assignment = new DettaglioEstrattoConto();
    assignment.assignToObject(json);
    return assignment;
  }

  static getListMeta(json: any) {
    let meta = {
      "count": json.count,
      "zeroBasedPageIndex": json.zeroBasedPageIndex,
      "totalPages": json.totalPages,
      "hasNextPage": json.hasNext,
      "hasPreviousPage": json.hasPrevious,
    }
    return meta
  }

}