export class TableStructure {

  public static readonly Lista: any[] = [
    {
			"title": "Data",
      "key": "parsed_date",
    },
    {
      "title":"Data Conferma",
      "key":"parsed_date_confirmed",
      "sort": 1,
    },
    {
      "title":"Nome Cognome",
      "key": "insoorer",
      "sort": "0",
    },
    {
			"title": "Telefono",
			"key": "telefono",
    },
    {
			"title": "Importo",
      "key": "importo",
      "sort": 2,
    },
    {
      "title": "Tipo Pagamento",
      "key": "tipoValue",
    }
  ]

  public static readonly Scadute: any[] = [
    {
      "title":"Nome Cognome",
      "key": "insoorer",
      "sort": "0",
    },
    {
			"title": "Importo",
      "key": "importo",
      "sort": 2,
    }
  ]

  public static readonly Turni: any[] = [
    {
			"title": "Inizio",
			"key": "dataInizio",
    },
    {
      "title":"Fine",
      "key": "dataFine"
    },
    {
			"title": "Compenso",
			"key": "totale",
    }
  ]

  public static readonly Rettifiche: any[] = [
    {
			"title": "Data",
			"key": "dataCreazione",
    },
    {
      "title":"Operatore",
      "key": "inseritaDa"
    },
    {
			"title": "Tipo rettifica",
			"key": "tipologia",
    },
    {
			"title": "Note",
			"key": "note",
    },
    {
			"title": "Compenso",
			"key": "importo",
    }
  ]

  public static readonly RimborsiKm: any[] = [
    {
			"title": "Km Rimborsati",
			"key": "kmRimborsati",
    },
    {
      "title":"Turno",
      "key": "data"
    },
    {
			"title": "ID Rilevazione",
			"key": "requestIdArrivo",
    },
    {
			"title": "Partenza",
			"key": "indirizzoPartenza",
    },
     {
			"title": "Arrivo",
			"key": "indirizzoArrivo",
    },
      {
			"title": "Compenso",
			"key": "importo",
    }
  ]

  public static readonly ElencoRilevazioni: any[] = [
    {
			"title": "ID Rilevazione",
			"key": "requestId",
    },
    {
			"title": "Data",
			"key": "data",
    },
    {
			"title": "Indirizzo",
			"key": "indirizzo",
    },
    {
      "title":"Tipologia",
      "key": "oggettoValue"
    },
     {
			"title": "Compenso",
			"key": "compenso",
    }
  ]
}