import * as moment from 'moment';

export class NotificationType {

  public static readonly RegistrationToProcess: number = 0;
  public static readonly InspectionExpired: number = 1;
  public static readonly Credit: number = 2;

  static getNotificationText(code: number) {

  }
  static getNotificationIconClass(code: number) {
    if (code == this.RegistrationToProcess) return "fa-check";
    if (code == this.InspectionExpired) return "fa-close";
    if (code == this.Credit) return "fa-flag";
  }
}

export class InspectionType {
  public static readonly Assuntiva: number = 0;
  public static readonly Crash: number = 1;

  static getInspectionTypeString(code: number) {
    if (code == this.Assuntiva) return "Assuntiva";
    if (code == this.Crash) return "Crash";

  }
}

export class InspectionStatus {
  public static readonly Unknown: number = 0;
  public static readonly Opened: number = 1;
  public static readonly InPending: number = 2;
  public static readonly WaitingForPhotos: number = 3;
  public static readonly ToApprove: number = 4;
  public static readonly Rejected: number = 5;
  public static readonly Accepted: number = 6;
  public static readonly CanceledByOperator: number = 7;
  public static readonly Canceled: number = 8;
  public static readonly Expired: number = 9;
  public static readonly Claimed: number = 51;
  public static readonly ToClaim: number = 50;
  public static readonly CanceledRefused: number = 10;
  public static readonly CanceledWaitingForApproval: number = 10;
  public static readonly WaitingForScheduledTime: number = 12;
  public static readonly ScheduledActive: number = 13;


  static getInspectionStatusString(code: number) {
    if (code == this.Unknown) return "Sconosciuto";
    if (code == this.Opened) return "Aperte";
    if (code == this.InPending) return "In corso";
    if (code == this.WaitingForPhotos) return "In attesa foto";
    if (code == this.ToApprove) return "Da approvare";
    if (code == this.Rejected) return "Rifiutate";
    if (code == this.Accepted) return "Accettate";
    if (code == this.Claimed) return "Periziate";
    if (code == this.ToClaim) return "Da periziare";
    if (code == this.CanceledByOperator) return "Eliminate";
    if (code == this.Canceled) return "Annullate";
    if (code == this.Expired) return "Scadute";
    if (code == this.ScheduledActive) return "Schedulate";
    if (code == this.WaitingForScheduledTime) return "Schedulate";
    if (code == this.CanceledWaitingForApproval) return "Annullate in attesa approvazione";
    if (code == this.CanceledRefused) return "Annullate rifiutate";
  }
}

export class SummarizedInspectionStatus {
  public static readonly Unknown: number = 0;
  public static readonly Opened: number = 1;
  public static readonly InspectionInProgress: number = 2;
  public static readonly WaitingForApproval: number = 3;
  public static readonly Accepted: number = 4;
  public static readonly Refused: number = 5;
  public static readonly Expired: number = 6;
  public static readonly Scheduled: number = 7;
  public static readonly Aborted: number = 9;
  public static readonly ToClaim: number = 10;
  public static readonly Claimed: number = 11;
  public static readonly ScheduledPending: number = 12;

  static getSummarizedInspectionStatusString(code: number) {
    if (code == this.Unknown) return "Sconosciuto";
    if (code == this.Opened) return "Aperte";
    if (code == this.ToClaim) return "Da periziare";
    if (code == this.Claimed) return "Periziate";
    if (code == this.Accepted) return "Accettate";
    if (code == this.Aborted) return "Eliminate";
    if (code == this.Expired) return "Scadute";
    if (code == this.Refused) return "Rifiutata";
    if (code == this.Scheduled) return "Schedulata"
  }
}

export class Dictionary {

  private static terms: any[] = [];
  private static termsValue: any[] = [];

  private static serviceListData = {};

  static cleanDict() {
    this.serviceListData = {};
  }

  static pupulateDict(keyName, valueName) {
    this.terms.push(keyName)
    this.termsValue.push(valueName);
  }

  static createDataDict() {

    let i = 0;
    for (let key of this.terms) {
      this.serviceListData[key] = this.termsValue[i]
      i++;
    }
    this.terms = [];
    this.termsValue = [];
    return this.serviceListData;
  }
}

export class Convert {

  static dateTimeToLocalDateTimeString(date) {
    if (date != "") {
      var inputDateYear: string = date.year.toString();
      var inputDateMonth: string = date.month.toString();
      var inputDateDay: string = date.day.toString();

      return (Convert.unixTimestampToLocalDateString(new Date(inputDateYear + "-" + inputDateMonth + "-" + inputDateDay).getTime() / 1000));
    }
  }

  static dateTimeToLocalDate(date) {
    if (date != "") {
      var inputDateYear: string = date.year.toString();
      var inputDateMonth: string = date.month.toString();
      var inputDateDay: string = date.day.toString();

      if (inputDateMonth.length == 1) inputDateMonth = "0" + inputDateMonth;
      if (inputDateDay.length == 1) inputDateDay = "0" + inputDateDay;

      return (inputDateDay + "-" + inputDateMonth + "-" + inputDateYear);
    }
  }

  static fromLongToDateFormatted(date) {
    let flightDateFormatted = {
      year: parseInt(moment(new Date(date * 1e3)).local().format("YYYY")), month: parseInt(moment(new Date(date * 1e3)).local().format("MM")), day:
        parseInt(moment(new Date(date * 1e3)).local().format("DD"))
    };

    return flightDateFormatted
  }

  static dateTimeToLocalDateString(date) {
    if (date != "") {
      var inputDateYear: string = date.year.toString();
      var inputDateMonth: string = date.month.toString();
      var inputDateDay: string = date.day.toString();

      return (inputDateYear + "-" + inputDateMonth + "-" + inputDateDay);
    }
  }

  static dateTimeToLocalDateTimeLong(date) {
    if (date != "") {
      var inputDateYear: string = date.year.toString();
      var inputDateMonth: string = date.month.toString();
      var inputDateDay: string = date.day.toString();

      return (new Date(inputDateYear + "-" + inputDateMonth + "-" + inputDateDay).getTime() / 1000);
    }
  }

  static dateToLocalDateTimeStringWithHoursMinutes(date, hours, minutes) {
    if (date != "") {
      var inputDateYear: string = date.year.toString();
      var inputDateMonth: string = date.month.toString();
      var inputDateDay: string = date.day.toString();

      return (new Date(inputDateYear + "-" + inputDateMonth + "-" + inputDateDay + " " + hours + ":" + minutes)).getTime() / 1000;
    }
  }

  static dateTimeToLocalDateTimeStringWithHoursMinutes(date, hours, minutes) {
    if (date != "") {
      return (new Date(date + " " + hours + ":" + minutes)).getTime() / 1000;
    }
  }

  static dateTimeToLocalDateTimeStringWithTime(date, time) {
    if (date != "") {
      return (new Date(date + " " + time)).getTime() / 1000;
    }
  }



  static dateStringToUnixLong(date) {
    if (date != "") {
      var inputDateYear: string = date.year.toString();
      var inputDateMonth: string = date.month.toString();
      var inputDateDay: string = date.day.toString();

      return (new Date(inputDateYear + "-" + inputDateMonth + "-" + inputDateDay)).getTime() / 1000;
    }
  }

  static dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'application/pdf' });
    return blob;
  }

  static unixTimestampToLocalDateTimeString(unixTimestamp: number) {
    if (unixTimestamp > 0) {
      return moment(new Date(unixTimestamp * 1e3)).local().format("DD-MM-YYYY | HH:mm")
    }

    else return "ND";
  }

  static unixTimestampToLocalDateString(unixTimestamp: number) {
    if (unixTimestamp > 0) {
      return moment(new Date(unixTimestamp * 1e3)).local().format("DD-MM-YYYY")
    }

    else return "ND";
  }

  static unixTimestampToDateString(unixTimestamp: number) {
    if (unixTimestamp > 0) {
      return moment(new Date(unixTimestamp * 1e3)).local().format("YYYY-MM-DD")
    }

    else return "ND";
  }

  static fromUnixTimestampToDateString(unixTimestamp: number) {
    if (unixTimestamp > 0) {
      return moment(new Date(unixTimestamp * 1e3)).local().format("DD-MM-YYYY | HH:mm")
    }

    else return "ND";
  }

  static unixTimeStampCalendarToDateString(unixTimeStamp: number) {
    if (unixTimeStamp > 0) {
      return moment(new Date(unixTimeStamp * 1e3)).toDate()
    }
  }

  static unixTimeStampCalendarAssegnazioneToDateString(unixTimeStamp: number) {
    if (unixTimeStamp > 0) {
      return moment(new Date(unixTimeStamp * 1e3)).toDate()
    }
  }

  static dateToMese(dataSplitted) {
    switch (dataSplitted) {
      case "01": return "Gennaio";
      case "02": return "Febbraio";
      case "03": return "Marzo";
      case "04": return "Aprile";
      case "05": return "Maggio";
      case "06": return "Giugno";
      case "07": return "Luglio";
      case "08": return "Agosto";
      case "09": return "Settembre";
      case "10": return "Ottobre";
      case "11": return "Novembre";
      case "12": return "Dicembre";
    }
  }
}

export class EventFriendlyNames {
  static getDescription(eventName: string) {
    var loweredEventName = eventName.toLowerCase();
    switch (loweredEventName) {
      case "scheduledrequestexpired":
        return "Perizia schedulata scaduta";
      case "scheduledrequestbookingcancelled":
        return "Perizia schedulata cancellata";
      case "scheduledinspectionrequeststarted":
        return "Perizia schedulata iniziata";
      case "allinspectionrequestphotouploaded":
        return "Tutte le foto inviate";
      case "backendoperatorcreated":
        return "Creato Operatore Backend";
      case "backendoperatordeleted":
        return "Cancellato Operatore Backend";
      case "backendoperatorgrantprofilechanged":
        return "Cambiato profile utente Backend";
      case "backendoperatorperformedfirstlogin":
        return "Primo Login Operatore Backend";
      case "companyadministratorassigned":
        return "Assegnato amministratore company";
      case "companyadministratordemoted":
        return "Rimosso amministratore company";
      case "companycreated":
        return "Creata Company";
      case "companycreditalmostexhausted":
        return "Credito company quasi terminato";
      case "companycreditexhausted":
        return "Credito company terminato";
      case "companydeleted":
        return "Company cancellata";
      case "creditaddedtocompany":
        return "Aggiunto credito company";
      case "emailconfirmed":
        return "Confermato indirizzo email";
      case "grantednewusertype":
        return "Forniti grant di accesso";
      case "inspectionphotovalidationfailed":
        return "Fallita validazione foto";
      case "inspectionpurchased":
        return "Acquistata inspection";
      case "inspectionrequestaborted":
        return "Annullata perizia";
      case "inspectionrequestaccepted":
        return "Perizia accettata";
      case "inspectionrequestacceptrollbackrequested":
        return "Richiesto rollback acquisto perizia";
      case "inspectionrequestbooked":
        return "Ispezione prenotata";
      case "inspectionrequestschedulebooked":
        return "Perizia schedulata prenotata";
      case "inspectionrequestactivated":
        return "Perizia schedulata attivata";
      case "inspectionrequestbookingcancelled":
        return "Prenotazione annullata";
      case "inspectionrequestbookingexpired":
        return "Prenotazione scaduta";
      case "inspectionrequestcompleted":
        return "Ispezione completata (da inviare)";
      case "inspectionrequestcreated":
        return "Ispezione creata";
      case "inspectionrequestexpired":
        return "Ispezione scaduta";
      case "inspectionrequestphotouploaded":
        return "Foto ispezione caricata";
      case "inspectionrequestrefused":
        return "Ispezione rifiutata";
      case "inspectionrequeststarted":
        return "Ispezione iniziata";
      case "inspectoravailabilitychanged":
        return "Cambiato stato disponibilità inspector";
      case "inspectorcreated":
        return "Creato inspector";
      case "inspectordeleted":
        return "Cancellato inspector";
      case "inspectorlocationupdated":
        return "Posizione inspector aggiornata";
      case "inspectorphotouploaded":
        return "Foto registrazione inspector caricata";
      case "inspectorregistrationstatuschanged":
        return "Aggiornato stato registrazione utente";
      case "inspectorrewardedforinspection":
        return "Aggiunto credito per inspection";
      case "inspectorwithdrawalrequest":
        return "Richiesta riscossione credito";
      case "operatorcreated":
        return "Creato operatore";
      case "operatordeleted":
        return "Cancellato operatore";
      case "operatorperformedfirstlogin":
        return "Primo Login Operatore Company";
      case "resendconfirmationemailrequested":
        return "Richiesto reinvio mail conferma";
      case "resetpasswordrequested":
        return "Richiesta reset password";
      case "revokedusertypegrant":
        return "Revocato grant utente";
      case "uploadphotosexpired":
        return "Scaduto tempo invio foto";
      case "usercreationsucceded":
        return "Utente creato con successo";
      case "userdeleted":
        return "Utente cancellato";
      case "withdrawalrequestaccepted":
        return "Richiesta riscossione accettata";
      case "withdrawalrequestbooked":
        return "Richiesta riscossione prenotata";
      case "withdrawalrequestrefused":
        return "Richiesta riscossione rifiutata";
      case "zoneassignmentremovedfrominspector":
        return "Zona rimossa dall'inspector";
      case "zoneassignedtoinspector":
        return "Zona assegnata all'inspector";
      case "forcefullyacceptedfailed":
        return "FALLITA accept forzata dell'inspection";
      default:
        return eventName;
    }
  }
}