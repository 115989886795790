

import * as moment from 'moment';

export class EstrattoContoNonRiscosso {

    constructor(
        public id: number = 0,
        public meseRiferimento: string = "",
        public importo: string = "",

    ) { }

    assignToObject(json: any) {
        let month = moment.unix(json.meseRiferimento).locale('it_IT').format('MMMM');
        month = month[0].toUpperCase() + month.substring(1);
        this.importo = `${json.importo}€`;
        this.meseRiferimento = month;
        this.id = json.id;
    }

    static fromJsonArray(json: any): EstrattoContoNonRiscosso[] {
        //return json;
        return json.map(j => {
            return this.fromJson(j)
        })
    }

    static fromJson(json: any): EstrattoContoNonRiscosso {
        let assignment: EstrattoContoNonRiscosso;
        assignment = new EstrattoContoNonRiscosso();
        assignment.assignToObject(json);
        return assignment;
    }


}