<button id="span" class="btn-mobile" (click)="openNav()">&#9776; Filtri</button>
<div id="sidebar-filter" class="filter" >
	<form [formGroup]="filterForm">
		<h4>Filtri <button id="span" href="javascript:void(0)" class="closebtn" (click)="closeNav()">&times;</button> </h4>
		
		<div class="flex" id="sidebar">
			<div class="form-group">
				<h6>Anno</h6>
				<select class="form-control" id="company_selector" (change)="setFilter('anno', $event)"
					formControlName="filterAnno">
					<option *ngFor="let item of filterConfiguration; let idx = index;" [value]="item.value">{{item.label}}</option>
				</select>
			</div>
			<div class="form-group">
				<h6>Mese</h6>
				<select class="form-control" id="company_selector" (change)="setFilter('mese', $event)"
					formControlName="filterMese">
					<option *ngFor="let m of months; let idx = index;" [value]="m">{{getMonthName(m)}}</option>
				</select>
			</div>
			<div *ngIf="listType != 'scadute'" class="form-group">
				<h6>Tipo Pagamento</h6>
				<select class="form-control" id="type_selector" (change)="setFilter('tipologiaPagamento', $event)" formControlName="filterTipologia">
					<option [value]="-1" >Tutti</option>
					<option [value]="0">Ricevuta</option>
					<option [value]="1">Fattura</option>
				</select>
		   
			</div>
		</div>
	</form>
</div>