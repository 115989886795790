import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-expired-account-statements-modal',
  templateUrl: './expired-account-statements-modal.component.html',
  styleUrls: ['./expired-account-statements-modal.component.scss'],
  providers: []
})
export class ExpiredAccountStatementsModalComponent implements OnInit {


  @Input() items: any[];

  public structure: any[] = [{ title: 'Mese', key: 'meseRiferimento' }, { title: 'Importo', key: 'importo' }];

  constructor(
    private activeModalService: NgbActiveModal
  ) {

  }

  ngOnInit() {
  }

  close() {
    this.activeModalService.dismiss();
  }

}
