

import { Convert } from 'src/app/Helpers/Utils';

export class EstrattoConto{

    constructor(
      public idEstrattoConto:number = 0,
      public insoorerId:string = "...",
      public insoorer:string = "...",
      public data:string = "...",
      public importo: string = "...",
      public numeroTurniEffettuati:number = 0,
      public totaleKmPercorsi:number = 0,
      public numeroRilevazioniSvolteFuoriTurno:number = 0,

    ){}
      public UnixTimeStamp = Convert.unixTimestampToLocalDateString;

    assignToObject(json:any){
      this.idEstrattoConto = json.id;
      this.insoorerId = json.insoorerId;
      this.insoorer = json.insoorer;
      this.numeroTurniEffettuati = json.numeroTurniEffettuati;
      this.numeroRilevazioniSvolteFuoriTurno = json.numeroRilevazioniSvolteFuoriTurno;
      this.totaleKmPercorsi = json.totaleKmPercorsi;
      this.data = this.UnixTimeStamp(json.data);
      this.importo = json.importo;
    }
    
  static fromJsonArray(json: any): EstrattoConto[] {
    //return json;
    return json.map(j => {
      return this.fromJson(j)
    })
  }

  static fromJson(json: any): EstrattoConto {
    let assignment: EstrattoConto;
    assignment = new EstrattoConto();
    assignment.assignToObject(json);
    return assignment;
  }

  static getListMeta(json: any) {
    let meta = {
      "count": json.count,
      "zeroBasedPageIndex":json.zeroBasedPageIndex,
      "totalPages": json.totalPages,
      "hasNextPage": json.hasNext,
      "hasPreviousPage": json.hasPrevious,
    }
    return meta
  }
}