import { Component, OnInit, Injectable, Input } from '@angular/core';

@Component({
  selector: 'app-new-loader',
  templateUrl: './new-loader.component.html',
  styleUrls: ['./new-loader.component.scss']
})

@Injectable()
export class NewLoaderComponent implements OnInit {

  constructor() { }
  @Input() showText:boolean = false;
  ngOnInit() {
  }

}
