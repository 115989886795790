import { Component, OnInit, Inject } from '@angular/core';
import { AuthService } from './Services/Authentication/auth.service';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Administration';
  
  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit() {
    //this.checkAuthentication();
    var url = new URL(window.location.href);
    var accessToken = url.searchParams.get("at");
    if(accessToken != null) localStorage.setItem("access_token", accessToken!);
  }

   /* checkAuthentication() {
    if (localStorage.getItem('access_token')) {
    }
    else if (location.origin.includes("localhost")) {
      this.authService.login("test@test.com", "Test@Whoosnap1")
        .subscribe(data => {
          localStorage.setItem('access_token', data.access_token);
          location.reload();
        })
    } else {
      window.location.href = environment.signup_url;
    }
  } */
}
