import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { environment } from '../../../environments/environment'
import { PreviousRouteService } from '../../Services/previous-route.service';
import { ServiceSearchService } from '../../Services/service-search.service';
import { OperatorsService } from '../../Services/Operatori/operator.service';
import { AuthService } from '../../Services/Authentication/auth.service';
import { Operator } from "../../Models/Operator";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public searchFor: string = "1";
  public searchValue: string = "";
  public formSearch: FormGroup;
  public nome: string = "";
  public cognome: string = "";
  public operator: Operator;
  public gotoUrl = environment.signup_url;
  public canEditUser:boolean = false;
  public InspectorPaymentsRead = false;
  public menu:boolean = false;
  private sub: Subscription = new Subscription();
  public platforms: any[];

  constructor(private authService: AuthService, private router: Router, private formBuilder: FormBuilder, private search: ServiceSearchService, private previousRouteService: PreviousRouteService, private operatorService: OperatorsService) {
    this.formSearch = this.formBuilder.group({
      FormInput: ['', Validators.compose([])],
      FormDrop: ['', Validators.compose([])],
    });

    /* this.platforms = [
      {label: 'AS', value: `${environment.assignmentUrl}/home/overView`, classList: 'set-icon assignemnt white'},
      {label: 'CM', value: environment.communityManagerUrl, classList: 'set-icon community white'},
      {label: 'UM', value: environment.usermanagerUrl,  classList: 'set-icon usermanger white'}
    ]; */
  }

  ngOnInit() {
    this.getMySelf();
  }

  getMySelf() {
    this.operatorService.getMyself().subscribe(data => {
      if (data.email) {
        this.nome = data.firstName;
        this.cognome = data.lastName;
        this.canEditUser = data.canEditUsers;
        this.InspectorPaymentsRead = data.grantProfile['availableGrants'].value.includes('InspectorPaymentsEdit');
      }
    })
  }

  keySearch() {
    let infoSearch = {
      "type": this.searchValue,
      "value": this.searchFor,
      "lista": true,
    }
    this.search.callSearch(infoSearch)

    if (this.previousRouteService.getCurrentUrl().includes("DettaglioUtente")) this.router.navigateByUrl("/CommunityManager/Lista")
  }

  setInput() {
    if (this.searchFor == "1") {
      this.searchValue = "";
      this.formSearch.controls['inputSearch'].setValue("", { onlySelf: true })
      this.formSearch.controls['inputSearch'].disable();
    }
    else this.formSearch.controls['inputSearch'].enable();
    //this.currentPage = 0;
  }

  navigateToOtherPlatform(listItem: any): void {
    window.open(listItem.value, "_self");
  }


}
