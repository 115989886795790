export const environment = {
	production: false,
	client_version: "PROD  v0.1.1",
	api_default_url: "https://administrationmanager-api.insoore.com/api/",
	api_auth_url: "https://auth.insoore.com",
	api_authapi_url: "https://auth-api.insoore.com",
	api_user_manager: "https://usermanager-api.insoore.com/api/v1/",
	api_booking: "https://booking-api.insoore.com//api/v1/",
	api_anagrafica_insoorer: "https://assignments-api.insoore.com/api/",
	api_account_url: "https://accounts-api.insoore.com/api/v1/",
	staffomatic_Api_Location: "https://api.staffomaticapp.com/v3/insoore-srl/locations.json",
	staffomatic_Api_LocationSchedules: "https://api.staffomaticapp.com/v3/insoore-srl/locations",
	staffomatic_Api_Users: "https://api.staffomaticapp.com/v3/insoore-srl/users.json?state=active",
	staffomatic_Api_Worker: "https://api.staffomaticapp.com/v3/insoore-srl/users.json?state=active",
	email: "info@insoore.com",
	psw: "Insoore#18",
	user_agent: "MyApp(info@insoore.com)",
	api_version: '1.0',
	google_api_key: "AIzaSyCLqY1DS-QBBPElV9WN7gyjdR0ifjBwvDU",
	client_id: 'be-operator-api',
	client_secret: 'InsooreBack#secret',
	grant_type_login: "password",
	gmap_default_start_point: {
		latitude: 41.900677,
		longitude: 12.504429,
		zoom: 13
	},
	max_users_for_account: 10,
	UseFakes: false,
	signup_url: 'https://accounts.insoore.com/',
	url_pre: 'https://administrationmanager.insoore.com',
	url: 'https://administrationmanager.insoore.com',

	communityManagerUrl: "https://communitymanager.insoore.com",
	assignmentUrl: "https://assignments.insoore.com/home/overView",
	usermanagerUrl: 'https://assignments.insoore.com/gestione-utenti/lista-utenti',
};