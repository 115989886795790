<div class="customTable " style="align-items: baseline; " id="table">

	<table class="table table-hover">
		<thead>
			<tr>
				<th *ngFor="let item of structure" class="th-sm" [ngClass]="{'sortable':item.sort}"
					(click)="sortBy(item.sort)">
					<div class="th-container">{{item.title}} </div>
				</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let item of items, let i = index;"
				[routerLink]="enabledRouterLink ? routerLink(item.tipoId, item.id): null">
				<td *ngFor="let col of structure" scope="row">
					<div class="td-container">
						<span>{{item[col.key]}}</span>
					</div>
				</td>
			</tr>
			<tr *ngIf="items?.length == 0 && !loader">
				<td class="empty-state" colspan="20">Non sono presenti dati</td>
			</tr>
			<!-- <tr *ngIf="items?.length == 0" [routerLink]="routerLink('0')">
					<td class="empty-state">05/06/2020</td>
					<td class="empty-state">Francesco Marconi</td>
					<td class="empty-state">339229393223</td>
					<td class="empty-state">220.00 €</td>
					
				</tr> -->
		</tbody>
	</table>
	<app-new-loader *ngIf="loader"></app-new-loader>
	<!--   <div id="contatore" style="float: left;">
    Totale risultati: <strong>{{meta.count}}</strong>
  </div>
  <div class="paginationContainer">
    <div  class="pagination">
      <div (click)="pageChanged('back')" class="btn back-page">&#10094;</div>
      <div class="btn current">{{this.currentPage}}</div>
      <div class="btn total-pages">di {{meta.totalPages||"0"}}</div>
      <div (click)="pageChanged('next')" class="btn next">&#10095;</div>
    </div>
    <div *ngIf="meta.count != 0" class="directTo">
      <label>Vai alla pagina</label>
      <input type="number" [max]="meta.totalPages" min="1" [(ngModel)]='goToPage' class="form-control">
      <button (click)="pageChanged('goTo')" type="button" class="btn btn-secondary orange">Vai</button>
    </div>
  </div> -->
</div>