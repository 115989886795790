
import { Convert } from 'src/app/Helpers/Utils';


export class RimborsiKm {
    public UnixTimeStamp = Convert.unixTimestampToLocalDateString;

    constructor(
        public id:number = 0,
        public kmRimborsati:number = 0,
        public inspectorId :string = "...",
        public kmTotali :number = 0,
        public importo :string = "...",
        public requestIdPartenza :string = "...",
        public indirizzoPartenza :string = "...",
        public requestIdArrivo :string = "...",
        public indirizzoArrivo :string = "...",
        public shiftId :number = 0,
        public data:string = "...",
    ){}


    assignToObject(json:any){
        this.id = json.id;

        this.inspectorId = json.inspectorId;
        this.kmTotali = json.kmTotali;
        this.kmRimborsati = json.kmRimborsati ? json.kmRimborsati.toFixed(2) : '';
        this.id = json.id
        this.importo = json.importo+"€";
        this.requestIdPartenza = json.requestIdPartenza;
        this.indirizzoPartenza = json.indirizzoPartenza;
        this.requestIdArrivo = json.requestIdArrivo;
        this.indirizzoArrivo = json.indirizzoArrivo;
        this.shiftId = json.shiftId;
        this.data = this.UnixTimeStamp(json.data)
      }


      static fromJsonArray(json: any): RimborsiKm[] {
        //return json;
        return json.map(j => {
          return this.fromJson(j)
        })
      }
    
      static fromJson(json: any): RimborsiKm {
        let assignment: RimborsiKm;
        assignment = new RimborsiKm();
        assignment.assignToObject(json);
        return assignment;
      }
}
