
  

  <div class="row content">
    <div class="col-auto align-self-center" *ngIf="showText">
        <h5>Caricamento risultati in corso..</h5>
    </div>
    <div class="col-auto">
        <div class="check">
            <div class="loader">
              <img src="../../../assets/img/bordo.png"  width="75px" alt="bordo">
            </div>
          </div>
    </div>
  </div>
      
    