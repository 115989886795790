import { Component, Input, OnInit, Output} from '@angular/core';
import { EventEmitter } from 'events';

@Component({
  selector: 'app-sidebar-dettaglio',
  templateUrl: './sidebar-dettaglio.component.html',
  styleUrls: ['./sidebar-dettaglio.component.scss']
})
export class SidebarDettaglioComponent implements OnInit {

      public deleteUser:boolean = false;

      @Input() user:any;

   /*  @Input() nome:string;
    @Input() cognome:string;
    @Input() email:string;
    @Input() telefono:string;
    @Input() contaLen:boolean; */

    @Output() deleteEvent:EventEmitter;

    constructor(){}

    ngOnInit(){
      if(this.user) console.log(this.user);
    }
 
    
	openNav()  {document.getElementById("sidebar-filter").style.width = "180px";}

	closeNav() {document.getElementById("sidebar-filter").style.width = "0";}
}
