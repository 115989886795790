import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TableStructure } from 'src/app/Helpers/TableStructure';
import { Fattura } from 'src/app/Models/Fattura';
import { GestioneUtente } from 'src/app/Models/GestioneUtente';
import { EstrattiContoService } from 'src/app/Services/EstrattiConto/EstrattiConto.service';
import { StoreItemService } from 'src/app/Services/store-item.service';
import { Convert } from "../../Helpers/Utils";
import { ModalService } from "../../Helpers/modal.service";
import { FattureService } from '../../Services/Fatture/fatture.service';
import { UtentiService } from '../../Services/Utenti/utenti.service';
import { ExpiredAccountStatementsModalComponent } from '../expired-account-statements-modal/expired-account-statements-modal.component';
const FileSaver = require('file-saver');

@Component({
  selector: 'app-dettaglio',
  templateUrl: './dettaglio.component.html',
  styleUrls: ['./dettaglio.component.scss']
})
export class DettaglioComponent implements OnInit {

  public tableStructureElencoRilevazioni: any[] = TableStructure.ElencoRilevazioni;
  public tableStructureRimborsiKm: any[] = TableStructure.RimborsiKm;
  public tableStructureTurni: any[] = TableStructure.Turni;
  public tableStructureRettifiche: any[] = TableStructure.Rettifiche;
  public dataDettaglio: any;
  public items: any[] = [];
  public itemsElencoRilevazioni: any[] = [];
  public itemsRimborsiKm: any[] = [];
  public itemsTurni: any[] = [];
  public itemsRettifiche: any[] = [];
  public itemUser: GestioneUtente;
  public id: string;
  public insoorerId: string;
  public typeId: string;
  public estrattoContoMensileId: string;
  public type: string;
  public mese: string;
  public dataConferma: string;
  public loader: boolean = false;
  public fattura: Fattura;
  public fatturaPagata: boolean = true;
  public modalType: boolean = false;
  public modalNote: boolean = false;
  public showDoc: boolean = false;
  public Myloader: boolean = false;
  public url: string = "";
  public note: string = "";
  public statoEstrattoConto: string;
  public statoIdEstrattoConto: string;
  public typeLista: string;
  public tipoDownload: string = ""


  public get isPagamentoRicevuta() {
    return this.dataDettaglio && this.dataDettaglio.inquadramentoRapporto && this.dataDettaglio.inquadramentoRapporto.id == 1
  }

  public get isPagamentoFattura() {
    return this.dataDettaglio && this.dataDettaglio.inquadramentoRapporto && this.dataDettaglio.inquadramentoRapporto.id == 0
  }


  constructor(private route: ActivatedRoute, private modalService: ModalService, private fattureService: FattureService, private utentService: UtentiService, private estrattoContoService: EstrattiContoService, private storeItemService: StoreItemService) { }

  ngOnInit() {
    this.loader = true;
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.typeId = params['type'];
      console.log(params);

      this.typeLista = params['typeLista'];
      console.log(this.typeLista)
      if (this.typeLista == "dapagare") this.typeLista = "Da" + "\xa0" + "Pagare";
      if (this.typeId == "0") this.tipoDownload = "Ricevuta";
      else this.tipoDownload = "Fattura";

      this.getFatturePrestazioni(this.id)
    })
  }

  confermaPagamento() {
    this.loader = true;
    this.fattureService.confermaPagamento(this.typeId, this.id)
      .subscribe(data => {
        this.fatturaPagata = true;
        this.loader = false;
      },
        err => {
          console.log(err.error.errorMessage);
          this.loader = false;
          this.modalService.simpleModal("ERRORE", err.error.errorMessage);
        });
  }

  public openExpiredDetail() {

    const modal = this.modalService.modalWithComponent('', '', ExpiredAccountStatementsModalComponent);
    modal.componentInstance.items = this.dataDettaglio.estrattiContoNonRiscossi.map((e) => {
      return { meseRiferimento: e.meseRiferimento, importo: e.importo }
    })
  }

  getFatturePrestazioni(id: string) {
    this.fattureService.getFattureRicevuteInsoorer(this.typeId, id)
      .subscribe(data => {
        this.fattura = data;
        this.insoorerId = data.insoorerId;
        this.fatturaPagata = data.pagata;
        this.estrattoContoMensileId = data.estrattoContoMensileId.toString();
        if (this.fattura.pagata) this.type = "PAGATA";
        if (!this.fattura.pagata) this.type = "DA PAGARE";



        this.getInsoorerInfo(this.insoorerId);
        this.getEstrattoConto(this.estrattoContoMensileId);
      });
  }

  getEstrattoConto(id) {
    this.estrattoContoService.getDettaglioMeseEstrattoConto(id)
      .subscribe(data => {
        this.dataDettaglio = data;
        this.dataConferma = data['dataConferma'];
        this.mese = Convert.dateToMese(this.dataDettaglio.data.substr(3, 2));
        this.loader = false;
        this.itemsElencoRilevazioni = data.compensiRilevazioni;
        this.itemsRimborsiKm = data.rimborsiKm;
        this.itemsTurni = data.compensiTurni;
        this.itemsRettifiche = data.rettifiche;
        this.statoEstrattoConto = data.statoValue;
        this.statoIdEstrattoConto = data.statoId;
      })
  }

  getInsoorerInfo(id) {
    this.utentService.getInspectorById(id).subscribe(data => {
      this.itemUser = data;
      console.log(this.itemUser)
    });
  }

  download() {
    this.loader = true;
    //console.log(this.typeId,this.insoorerId,this.estrattoContoMensileId)
    this.fattureService.downloadFattureRicevuteInsoorer(this.typeId, this.insoorerId, this.estrattoContoMensileId)
      .subscribe(download => {
        console.log(download)
        this.loader = false;
        let fileData = download['body'].data.fileData;
        let filename = download['body'].data.suggestedFileName;
        let file = Convert.dataURItoBlob(fileData);

        FileSaver.saveAs(file, filename);

      },
        err => {
          this.loader = false;
          this.openModale("chiudi")
          this.modalService.simpleModal("ERRORE", "Il documento non è stato ancora caricato dall'Insoorer");
        });
  }

  openModale(type) {
    this.modalType = true;
    if (type == "note") this.modalNote = true;
    if (type == "view") { this.showDoc = true; this.modalAnteprima() }
    if (type == "chiudi") { this.modalType = false; this.showDoc = false; this.modalNote = false; }
    this.note = "";
  }

  rifiutAllegati() {

    this.modalType = false;
    this.loader = true;

    this.fattureService.rifiutaAllegato(this.estrattoContoMensileId, this.note)
      .subscribe(data => {
        this.loader = false;
        this.ngOnInit()
        this.modalService.simpleModal("Rifiuta Allegato", "Allegato rifiutato correttamente");
      },
        err => {
          this.loader = false;
          this.modalService.simpleModal("ERRORE", err.error.errorMessage);
        })
  }

  modalAnteprima() {
    this.Myloader = true;
    this.fattureService.anteprimaDoc(this.typeId, this.insoorerId, this.estrattoContoMensileId)
      .subscribe(download => {
        this.Myloader = false;
        console.log(download)
        this.url = download['data']['url'];
      },
        err => {
          console.log(err)
          this.Myloader = false;
          this.modalType = false;
          this.modalService.simpleModal("", err['error']['errorCode'] + "\n" + err['error']['errorMessage']);
        });
  }

  goBack() {
    window.history.back();
  }


}