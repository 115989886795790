import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './Components/home/home.component';
import {ListaComponent} from './Components/lista/lista.component';
import {DettaglioComponent} from './Components/dettaglio/dettaglio.component'


const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      {
        path: '',
        component: ListaComponent
      },
      {
        path: 'search/:type/',
        component: ListaComponent
      },
      {
        path: 'dettaglio/:typeLista/:type/:id',
        component: DettaglioComponent,
      }],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
