import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { ApiVersion } from 'src/app/Enums/ApiVersion';
import { Fattura } from 'src/app/Models/Fattura';
import { Fatture } from '../../Models/Fatture';
import { Endpoints, NetworkingService } from '../Networking/networking.service';

@Injectable({
  providedIn: 'root'
})

export class FattureService {

  constructor(private networking: NetworkingService) { }

  getScaduteDates() {
    return this.networking.get('v1/EstrattiConto/filtriDate', this.networking.getAuthenticatedHeader(), 'application/json').map((data: any) => data.data);
  }

  getFatture(filter: {}, type: string = ""): Observable<any> {
    let queryString = "";

    if (type == "") return
    else queryString += type;

    if (filter['anno'] != undefined) {
      if (filter['anno'] == "0") filter['anno'] = "2020";
      queryString += "?anno=" + filter['anno'];
    }
    if (filter['mese'] != undefined && filter['mese'] != "0") queryString += "&mese=" + filter['mese'];
    if (filter['tipoPagamento'] != undefined && filter['tipoPagamento'] != "-1") queryString += "&tipoPagamento=" + filter['tipoPagamento'];
    if (filter['sortField'] != undefined) queryString += "&sortField=" + filter['sortField'];
    if (filter['sortDirection'] != undefined) queryString += "&sortDirection=" + filter['sortDirection'];

    let url = ApiVersion.v2 + "/Administration/pagamenti/" + queryString;
    if (type == 'scadute') {
      url = url.replace('v2/Administration/pagamenti/scadute', 'v1/EstrattiConto/estrattiContoNonRiscossiByDate');
      console.log('URL SCADUTE', url);
    }

    return this.networking.get(url, this.networking.getAuthenticatedHeader(), 'application/json')
      .map((data: any) => Fatture.fromJsonArray((type == 'scadute') ? data.data : data));
  }

  confermaPagamento(tipoPagamento, id) {

    return this.networking.put(ApiVersion.v2 + "/Administration/pagamenti/" + id + "?tipoPagamento=" + tipoPagamento, {}, this.networking.getAuthenticatedHeader(), 'application/json')
      .map(
        data => {
          return data
        });
  }

  getFattureRicevuteInsoorer(typeId: string, id: string): Observable<Fattura> {
    let urlRequest = "";

    if (typeId == "1") urlRequest = "/Administration/FattureInsoorer/"
    else if (typeId == "0") urlRequest = "/Administration/RicevutePrestazioniOccasionali/"

    return this.networking.get(ApiVersion.v2 + urlRequest + id, this.networking.getAuthenticatedHeader(), 'application/json')
      .map(data => {
        return new Fattura(data);
      })
  }


  downloadFattureRicevuteInsoorer(typeId: string, insoorerId: string, estrattoContoMensileId: string) {
    let urlRequest = "";

    if (typeId == "1") urlRequest = "/Administration/FattureInsoorer/download"
    else if (typeId == "0") urlRequest = "/Administration/RicevutePrestazioniOccasionali/download"

    return this.networking.post(ApiVersion.v2 + urlRequest + "?insoorerId=" + insoorerId + "&estrattoContoMensileId=" + estrattoContoMensileId, {}, this.networking.getAuthenticatedHeader(), 'application/json', Endpoints.default, 'blob')
      .map(
        data => {
          return data
        });
  }


  rifiutaAllegato(id: string = null, note: string = "") {
    if (id == null) return;

    return this.networking.put(ApiVersion.v2 + "/Administration/pagamenti/" + id + "/rifiutaallegato", { "note": note }, this.networking.getAuthenticatedHeader(), 'application/json')
      .map(data => {
        return data;
      })
  }

  anteprimaDoc(typeId: string, insoorerId: string = null, estrattoContoMensileId: string = null) {
    let urlRequest = "";

    if (typeId == "1") urlRequest = "/Administration/FattureInsoorer/download"
    else if (typeId == "0") urlRequest = "/Administration/RicevutePrestazioniOccasionali/download"

    if (insoorerId) urlRequest += "?insoorerId=" + insoorerId;
    if (estrattoContoMensileId) urlRequest += "&estrattoContoMensileId=" + estrattoContoMensileId;

    return this.networking.get(ApiVersion.v2 + urlRequest, this.networking.getAuthenticatedHeader(), 'application/json')
      .map(data => {
        return data
      })
  }
}


