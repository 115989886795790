import { Convert } from 'src/app/Helpers/Utils';

export class CompensiRilevazioni {
    constructor(
        public id:number=0,
        public data: string = "...",
        public insoorerId: string = "...",
        public requestId:string = "...",
        public indirizzo:string = "...",
        public oggettoId:number = 0,
        public oggettoValue:string = "...",
        public compenso: string = "...",
        public turnoId:number = 0,
    ){
    }

    public UnixTimeStamp = Convert.unixTimestampToLocalDateString;

    assignToObject(json:any){
        this.id = json.id;
        this.insoorerId = json.insoorerId;
        this.data = this.UnixTimeStamp(json.data);
        this.requestId = json.requestId;
        this.indirizzo = json.indirizzo;
        this.compenso = json.compenso+"€";
        this.turnoId = json.turnoId;
        this.oggettoId =  json.oggetto ? json.oggetto.id : [];
        this.oggettoValue =  json.oggetto ? json.oggetto.value : [];
      }
      
    static fromJsonArray(json: any): CompensiRilevazioni[] {
      //return json;
      return json.map(j => {
        return this.fromJson(j)
      })
    }
  
    static fromJson(json: any): CompensiRilevazioni {
      let assignment: CompensiRilevazioni;
      assignment = new CompensiRilevazioni();
      assignment.assignToObject(json);
      return assignment;
    }
}