import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class ModalService {

  constructor(public modal: NgbModal) {
  }

  simpleModal(title: string, text: string) {
    this.modal.open(text, { size: 'sm' });
  }

  modalWithComponent(title: string, text: string, component) {
    return this.modal.open(component, { size: 'lg' });
  }

  mupliteErrorModal(errors: any) {
    let html = "";

    for (let error of errors) {
      html = html + `- ` + error;
    }

    this.modal.open(html, { size: 'sm' });
  }

  errorModal(error: string) {
    let html = `<div class="row modal-dialog col-lg-9" style="text-align:left;">
    <div class="modal-content alert" style="margin-top:0;">
      <div class="header-alert">
            <button type="button" class="close" data-dismiss="modal"></button>
        <span class="bell"></span>
        <h5 class="black">ERRORE</h5>
      </div>
      <div class="modal-body">
        <p>
        `+ error + `
        </p>            
      </div>
    </div>
  </div>`
    this.modal.open(html, { size: 'sm' });
  }

  imgModal(url: string, title: string, isBase64: boolean) {
    let html
    if (url) {
      if (isBase64)
        html = `<img class='modalPicture' src="data:image/jpeg;base64,` + url + `">`
      else
        html = `<img class='modalPicture' src="` + url + `">`
    }
    else {
      html = `<h1 class="picError">Foto non disponibile</h1>`
    }

    this.modal.open(html, { size: 'sm' });
  }

  /*confirmDialog(title: string, text: string, completion: () => void, okString: string = 'Conferma', cancelString: string = 'Annulla', showClose: boolean = true, isBlocking: boolean = false) {
    this.modal.confirm()
      .size('lg')
      .isBlocking(isBlocking)
      .showClose(showClose)
      .keyboard(27)
      .title(title)
      .body(text)
      .okBtn(okString)
      .cancelBtn(cancelString)
      .open().then(result => {
      result.result.then(result => {
        completion();
      }, err => {
        //swallow
      });
    });
  }


  modalWithComponent(component, data, completion: (string) => void) {
    this.modal.open(component, overlayConfigFactory({data: data}, BSModalContext))
      .then(resultPromise => {
        resultPromise.result.then(result => {
          if (result) {
            console.log(result)
            completion(result)
          }
        }, err => {
          console.log('error')
        });
      })
  }


  errorModal(error) {
    if (error.is_business_error) {
      this.simpleModal(`Si è verificato un errore (${error.error_code})`, `${error.error_message}`);
    } else {
      this.simpleModal(`Errore (${error.error_code})`, 'Si è verificato un errore');
    }
  }*/


}