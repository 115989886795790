export class IdValue {
    id: number;
    value: string;


    constructor(json) {
        if (!json) return;
        this.id = json.id;
        this.value = json.value;
    }
}