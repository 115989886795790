export class Operator {
    constructor(
        public authId: string = "...",
        public email: string = "...",
        public firstName: string = "...",
        public grantProfile: any[] = [],
        public havePerformedFirstLogin: boolean = false,   
        public canEditUsers:boolean= false,     
        public id: string = "...",
        public lastName: string = "...",
        public phoneNumber: string = "...",
      ) { }
    
      assignToObject(json: any) {
        this.authId = json.authId;
        this.email = json.email;
        this.grantProfile = json.grantProfile ? json.grantProfile : [];
        this.firstName = json.firstName;
        this.canEditUsers = json.canEditUsers ? json.canEditUsers : false;
        this.havePerformedFirstLogin = json.havePerformedFirstLogin;
        this.id = json.id;
        this.lastName = json.lastName;
        this.phoneNumber = json.phoneNumber;
      }
    
      static fromJson(json: any): Operator {
        let template = new Operator();
        template.assignToObject(json);
        return template;
      }
}
