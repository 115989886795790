<nav class="navbar customProps navbar-expand-lg" >

  <a class="navbar-brand" style="height: inherit;"  id="Logo">
    <img src="../../../assets/img/insoore-marchio.png" class="logo" alt="Logo_Insoore" [routerLink]="'/'">
    <div class="collapse show" id="Menu">
      <ul class="menu">
        <li *ngFor="let p of platforms">
          <span type="button" [ngClass]="p.classList" (click)="navigateToOtherPlatform(p)">
            {{p.label}}
          </span>
        </li>
      </ul>
    </div>
  </a>


  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarColor03"
    aria-controls="navbarColor03" aria-expanded="false" aria-label="Toggle navigation">
    <span></span>
    <span></span>
    <span></span>
  </button>
  <!-- <div class="col-auto">
    <form [formGroup]="formSearch">
      <div class="input-group ">
        <input id="inputField" [(ngModel)]="searchValue" placeholder="Cerca" (keyup.enter)="keySearch()" class="search" type="text"
          formControlName="FormInput">
        <div class="input-group-append ">
          <select [(ngModel)]="searchFor" id="select" class="form-control" formControlName="FormDrop" (change)="setInput()">
            <option value="1">Tutti</option>
            <option value="nome">Nome \ Cognome</option>
            <option value="email">Email</option>
          </select>
          <button class="btn btn-primary" style="width: 140px; margin-right: -2px;" (click)="keySearch()"
            type="submit">Cerca</button>
        </div>
      </div>
    </form>

  </div> -->
  <div class="collapse navbar-collapse" style="display:block !important" id="navbarColor03">
    <div class="float-right text-right pl-2 pr-3" style="width: fit-content;" id="InfoUtente">
      <label for="Nome">{{nome}}</label>
      <span class="white">{{cognome}}</span>
    </div>
  </div>
  <a href="{{gotoUrl}}" class="d-flex pl-4 pr-3">
    <img src="../../../assets/img/exit.png" alt="logOut" style="padding-right: 7px;">
    <span class="white"></span>
  </a>
</nav>
