import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ServiceSearchService {

  constructor() { }

  private searchCalled = new Subject<any>();

  searchCalled$ = this.searchCalled.asObservable();

  callSearch(searchData: any) {
    this.searchCalled.next(searchData);
  }

  

}
