import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PreviousRouteService } from 'src/app/Services/previous-route.service';
import { StoreItemService } from 'src/app/Services/store-item.service';


@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
  @Output() sortEvent: EventEmitter<number> = new EventEmitter();
  @Output() pageChangeEvent: EventEmitter<any> = new EventEmitter();
  @Input() link: string;
  @Input() structure: any[];
  @Input() items: any[];
  @Input() meta: any;
  @Input() loader: boolean;
  @Input() typeLista: string;
  @Input() enabledRouterLink: boolean = true;

  public goToPage: number = 1;
  public currentPage: number = 1;
  public savedPage;


  constructor(private storeItemService: StoreItemService, private getPreviousUrl: PreviousRouteService) { }

  ngOnInit() {
  }

  routerLink(typeId: string, id: string) {
    console.log(typeId, id);

    if (this.typeLista != 'scadute') return "/dettaglio/" + this.typeLista + "/" + (typeId) + "/" + id;
  }

  sortBy(type: number) {
    if (this.items.length == 0) return
    console.log(type)
    this.sortEvent.emit(type)
  }

  pageChanged(type) {

    this.storeItemService.write("savedPage", []);

    if (type == "back" && this.meta.hasPrevious == true) {
      this.currentPage -= 1;
      this.pageChangeEvent.emit(this.currentPage)
    }

    if (type == "next" && this.meta.hasNext == true) {
      this.currentPage += 1
      this.pageChangeEvent.emit(this.currentPage)
    }

    if (type == "goTo") {
      if (this.goToPage <= this.meta.totalPages && this.goToPage >= 1) {
        this.currentPage = this.goToPage
        this.pageChangeEvent.emit(this.goToPage)
        this.goToPage = 1;
      }
    }
    let page = {
      "page": this.currentPage,
      "meta": this.meta.count
    }
    this.storeItemService.write("savedPage", page);

  }

}
