import * as moment from 'moment';

export class Fatture {
    constructor(
        public date: number = 0,
        public dataConferma: number = 0,
        public id: string = "...",
        public insoorer: string = "...",
        public tipo: any[] = [],
        public tipoId: string = "...",
        public tipoValue:string = "...",
        public telefono: string = "...",
        public importo: string = "...",
        public amount: number = 0,
    ) { }

    assignToObject(json: any) {
        this.date = json.data;
        this.dataConferma = json.dataConferma;
        this.id = json.id;
        this.tipo = json.tipo;
        this.tipoValue = json.tipo ? json.tipo['value'] : [];
        this.tipoId = json.tipo ? json.tipo['id'] : 0;
        this.insoorer = json.insoorer;
        this.telefono = json.telefono;
        this.importo = json.importo+"€";
        this.amount = json.importo;
    }

    static fromJsonArray(json: any): Fatture[] {
        return json.map(j => {
            return this.fromJson(j)
        })
    }

    static fromJson(json: any): Fatture {
        let fatture: Fatture;
        fatture = new Fatture();
        fatture.assignToObject(json);
        return fatture;
    }

  public get parsed_date(): string {
    if (this.date) {
      return moment.unix(this.date).format('DD-MM-YYYY')
    } else {
      return "ND";
    }
  }

  public get parsed_date_confirmed(): string {
    if (this.dataConferma) {
      return moment.unix(this.dataConferma).format('DD-MM-YYYY')
    } else {
      return "ND";
    }
  }

}
