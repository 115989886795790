import { IdValue } from "./IdValue";

export class GestioneUtente {
    id: string;
    tipologiaLavoro: IdValue;
    nome: string;
    cognome: string;
    nomeCognome: string;
    indirizzo: string;
    partitaIVA: string;
    codiceFiscale: string;
    email: string;
    cellulare: string;
    inquadramentoRapporto: IdValue;
    latitudinePartenza: number;
    longitudinePartenza: number;
    dataDiNascita: string;
    indirizzoPartenza: string;
    luogoDiNascita: string;
    iban: string;
    cityId: number;
    dotazioni: IdValue[];

    constructor(data) {
        if (!data) return;
        this.id = data.id;
        this.tipologiaLavoro = data.tipologiaLavoro;
        this.nome = data.nome;
        this.cognome = data.cognome;
        this.nomeCognome = data.nomeCognome;
        this.indirizzo = data.indirizzo;
        this.partitaIVA = data.partitaIVA;
        this.codiceFiscale = data.codiceFiscale;
        this.email = data.email;
        this.cellulare = data.cellulare;
        this.inquadramentoRapporto = data.inquadramentoRapporto;
        this.latitudinePartenza = data.latitudinePartenza;
        this.longitudinePartenza = data.longitudinePartenza;
        this.dataDiNascita = data.dataDiNascita;
        this.indirizzoPartenza = data.indirizzoPartenza;
        this.luogoDiNascita = data.luogoDiNascita;
        this.iban = data.iban;
        this.cityId = data.cityId;
        this.dotazioni = data.dotazioni;
    }

}