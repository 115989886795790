<div class="row">
    <div class="col-12">
        <h3>Dettaglio estratti conto scaduti</h3>
    </div>



    <div class="col-12">
        <app-table [structure]="structure" [items]="items" class="w-100" [enabledRouterLink]="false"></app-table>
    </div>

    <div class="col-12 align-self-end text-right">
        <button type="button" class="btn btn-primary" style="margin: 0 auto; border-radius: 5px;"
            (click)="close()">Chiudi</button>
    </div>

</div>