
import { Convert } from 'src/app/Helpers/Utils';

export class CompensiTurni {
    constructor(
          public id: number = 0,
          public insoorerId: string = "...",
          public turnoId: number = 0,
          public dataInizio: string = "...",
          public dataFine: string = "...",
          public indennita: string = "...",
          public totale:string = "...",
    ){}


    public UnixTimeStamp = Convert.unixTimestampToLocalDateTimeString;

    assignToObject(json:any){
        this.id = json.id;
        this.insoorerId = json.insoorerId;
        this.turnoId = json.turnoId;
        this.dataInizio = this.UnixTimeStamp(json.dataInizio)
        this.dataFine = this.UnixTimeStamp(json.dataFine)
        this.indennita = json.indennita+"€";
        this.totale = json.totale+"€";
    }


      static fromJsonArray(json: any): CompensiTurni[] {
        //return json;
        return json.map(j => {
          return this.fromJson(j)
        })
      }
    
      static fromJson(json: any): CompensiTurni {
        let assignment: CompensiTurni;
        assignment = new CompensiTurni();
        assignment.assignToObject(json);
        return assignment;
      }
}