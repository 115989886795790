import { Injectable } from '@angular/core';
import { NetworkingService} from '../Networking/networking.service';
import {Operator} from '../../Models/Operator';

@Injectable()
export class OperatorsService {

constructor(private networking: NetworkingService) { }

getMyself() {
  return this.networking.get("v1/operators/myself", this.networking.getAuthenticatedHeader(), 'application/json')
    .map(
      data => {
        return Operator.fromJson(data)
      });
}

}
