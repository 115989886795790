export class Fattura {

    id: number;
    insoorerId: string;
    intestazione: string;
    indirizzoInsoorer: string;
    partitaIVA: string;
    numeroFattura: string;
    data: number;
    descrizione: string;
    importoEstrattoContoMensile: number;
    importo: number;
    importoPregresso: number;
    importoMese: number;
    importoFattura: number;
    importoImponibile: number;
    estrattoContoMensileId: number;
    pagata: true

    constructor(data) {
        this.id = data ? data.id : null;
        this.insoorerId = data ? data.insoorerId : null;
        this.intestazione = data ? data.intestazione : null;
        this.indirizzoInsoorer = data ? data.indirizzoInsoorer : null;
        this.partitaIVA = data ? data.partitaIVA : null;
        this.numeroFattura = data ? data.numeroFattura : null;
        this.data = data ? data.data : null;
        this.descrizione = data ? data.descrizione : null;
        this.importoEstrattoContoMensile = data ? data.importoEstrattoContoMensile : null;
        this.importo = data ? data.importo : null;
        this.estrattoContoMensileId = data ? data.estrattoContoMensileId : null;
        this.pagata = data ? data.pagata : null;
        this.importoPregresso = data ? data.importoPregresso : null;
        this.importoMese = data ? data.importoMese : null;
        this.importoFattura = data ? data.importoFattura : null;
        this.importoImponibile = data ? data.importoImponibile : null;
    }


    static fromJsonArray(data: any[]): Fattura[] {
        return data.map<Fattura>((d) => new Fattura(d))
    }

}